import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { CloseButton, FormatNumber, NumberUpDown, IconButton, Notification } from './../../../../../components'
import { IoCartOutline } from 'react-icons/io5'

const noImgUrl = '/assets/images/no-img.jpg'

const apiUrl = window.apiUrl

const ModalDetail = (props) => {
    const [qty, setQty] = useState(0)

    const [productId, setProductId] = useState(0)

    const [parentInfo, setParentInfo] = useState({
        detail: '',
        avatarUrl: '',
        customerPrice: 0,
        partnerPrice: 0,
        productName: ''
    })

    const [children, setChildren] = useState([])

    useEffect(() => {
        if (props.data.Parent !== undefined) {
            const parent = props.data.Parent
            const avatarUrl = (parent.AvatarUrl === '' || parent.AvatarUrl === null ? noImgUrl : `${apiUrl}/admin/storage/thumbnail?url=${parent.AvatarUrl}&size=300`)
            setParentInfo({
                detail: parent.Detail,
                avatarUrl: avatarUrl,
                customerPrice: parent.CustomerPrice,
                partnerPrice: parent.PartnerPrice,
                productName: parent.ProductName
            })
        }
        if (props.data.Children !== undefined) {
            const childrenArray = props.data.Children.map(item => {
                return {
                    ProductId: item.ProductId,
                    ExtensionName: item.ExtensionName,
                    IsSelected: false,
                    Quantity: item.Quantity,
                    StatusId: item.ProductStatusId
                }
            })
            setChildren(childrenArray)
        }
    }, [props.data])

    const renderChildren = () => {
        return children.map(item => {
            let extName = item.ExtensionName.trim()
            if (extName !== '' || extName != null) {
                if (extName.startsWith('-')) {
                    extName = extName.substring(1).trim()
                }
            }
            const isDisabled = (item.Quantity < 1 || item.StatusId !== 1)
            return <button key={item.ProductId}
                disabled={isDisabled}
                type="button" className={item.IsSelected ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success'}
                onClick={() => {
                    const childrenArray = [...children]
                    for (let i = 0; i < childrenArray.length; i++) {
                        childrenArray[i].IsSelected = false
                    }
                    const index = childrenArray.findIndex(n => n.ProductId === item.ProductId)
                    childrenArray[index].IsSelected = true
                    setChildren(childrenArray)
                    setProductId(item.ProductId)
                }}
            >{extName} - <FormatNumber>{item.Quantity}</FormatNumber></button>
        })
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                setQty(0)
                setProductId(0)
            }}
            onShow={() => {

            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chi tiết sản phẩm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-3">
                        <p className="bg-img" style={{ background: `url('${parentInfo.avatarUrl}')` }}></p>
                    </div>
                    <div className="col-md-9">
                        <h3 className="mt-0">{parentInfo.productName}</h3>
                        <div className="row">
                            <div className="col-6">
                                <div className="mt-2">
                                    <h6 className="font-14">Giá đề xuất</h6>
                                    <h3>
                                        <FormatNumber>
                                            {parentInfo.customerPrice}
                                        </FormatNumber>
                                    </h3>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mt-2">
                                    <h6 className="font-14">Giá cộng tác viên</h6>
                                    <h3>
                                        <FormatNumber>
                                            {parentInfo.partnerPrice}
                                        </FormatNumber>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 button-list">
                            {renderChildren()}
                        </div>
                        <div className="mt-3">
                            <h6 className="font-14">Số lượng</h6>
                            <div className="d-flex">
                                <NumberUpDown
                                    className="w-150-px me-2"
                                    value={qty}
                                    onChange={setQty}
                                />
                                <IconButton
                                    text="Thêm vào giỏ hàng"
                                    variant="primary"
                                    icon={<IoCartOutline />}
                                    onClick={(setIsBusyBtn) => {
                                        if (productId === 0) {
                                            Notification.warning('Vui lòng chọn sản phẩm trước khi thêm vào giỏ hàng')
                                            return
                                        }
                                        if (qty < 1) {
                                            Notification.warning('Vui lòng chọn số lượng sản phẩm trước khi thêm vào giỏ hàng')
                                            return
                                        }
                                        props.onSave({
                                            Products: [
                                                {
                                                    ProductId: productId,
                                                    Quantity: qty
                                                }
                                            ]
                                        }, setIsBusyBtn)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3" dangerouslySetInnerHTML={{ __html: parentInfo.detail }} />
            </Modal.Body>
            <Modal.Footer>
                <CloseButton onClick={props.onClose} />
            </Modal.Footer>
        </Modal>
    )
}

ModalDetail.propTypes = {
    isShow: PropTypes.bool,
    data: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func
}

ModalDetail.defaultProps = {
    isShow: false,
    data: {},
    onSave: () => { },
    onClose: () => { }
}

export default ModalDetail