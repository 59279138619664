import React from 'react'

function Welcome(props) {
    return (
        <div className="row justify-content-center">
            <div className="col-lg-4">
                <div className="text-center mt-5">
                    <img src="/assets/images/file-searching.svg" height="90" alt="" />
                    <h4 className="text-uppercase text-danger mt-3">Chào mừng</h4>
                    <p className="text-muted mt-3">Bạn đến với hệ thống quản lý đơn hàng Rado Vietnam</p>
                </div>
            </div>
        </div>
    )
}

export default Welcome