import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, FormatNumber, Notification, NumberUpDown, SaveButton, AvatarRow } from './../../../../../components'

const schema = yup.object().shape({
    Products: yup.array().nullable()
})

const ModalAddToCart = (props) => {
    const { setValue, control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const renderTable = () => {
        return props.products.map((item, index) => <tr key={item.ProductId}>
            <td className="text-center">{index + 1}</td>
            <td>
                <AvatarRow
                    url={item.AvatarUrl}
                />
                {item.ProductName}
            </td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {renderQty(item)}
                </FormatNumber>
            </td>
            <td className="w-220-px">
                {renderNumberInput(item, index)}
            </td>
        </tr>)
    }

    const renderQty = (item) => {
        if (item.ProductStatusId !== 1) return 0
        else return item.Quantity
    }

    const renderNumberInput = (item, index) => {
        if (item.Quantity > 0 && item.ProductStatusId === 1) {
            return <Controller
                control={control}
                name={`Products.${index}.Quantity`}
                render={({
                    field: { onChange, onBlur, value, name, ref }
                }) => (
                    <NumberUpDown
                        onChange={onChange}
                    />
                )}
            />
        }
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                const products = props.products.map(item => {
                    return {
                        ProductId: item.ProductId,
                        Quantity: 0
                    }
                })
                setValue('Products', products)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm vào giỏ hàng</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                const products = formData.Products.filter(n => n.Quantity !== undefined && n.Quantity !== 0)
                formData.Products = products
                if (formData.Products.length === 0) {
                    Notification.warning('Vui lòng nhập số lượng sản phẩm trước khi thêm vào giở hàng')
                    return
                }
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-centered table-input mb-0">
                            <thead>
                                <tr>
                                    <th className="w-52-px">#</th>
                                    <th>Tên sản phẩm</th>
                                    <th className='text-end'>Kho hàng</th>
                                    <th>Số lượng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTable()}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAddToCart.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    products: PropTypes.array
}

ModalAddToCart.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    products: []
}

export default ModalAddToCart