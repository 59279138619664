import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import { IoShareSocialOutline } from 'react-icons/io5'
import NumberFormat from 'react-number-format'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Select from 'react-select'
import * as yup from "yup"
import { CategoryDropdownTree, CloseButton, Config, EmptyRow, IconButton, InputSelect, LoadingRow, SaveButton, UploadImg } from './../../../../../components'

const invalidValue = 'Giá trị không hợp lệ'

const schema = yup.object().shape({
    ProductName: yup.string().required('Vui lòng nhập tên sản phẩm').max(200, 'Tên sản phẩm quá dài'),
    ProductCode: yup.string().required('Vui lòng nhập mã sản phẩm').max(50, 'Mã sản phẩm quá dài'),
    CategoryId: yup.number().min(1, 'Vui lòng chọn nhóm sản phẩm'),
    InputPrice: yup.number().min(0, invalidValue),
    OutputPrice: yup.number().required('Vui lòng nhập giá bán').min(0, invalidValue),
    StatusId: yup.number().oneOf([1, 2, 3], invalidValue),
    Detail: yup.string().max(4_000, 'Chi tiết sản phẩm quá dài'),
    AvatarUrl: yup.string().max(2_000, 'Đường dẫn ảnh quá dài'),
    ProductPrices: yup.array(),
    SubProducts: yup.array(),
    ProductChildrentAttribute: yup.array()
})

const getCombn = arr => {
    if (arr.length === 1) {
        return arr[0]
    }
    else {
        var ans = []
        var otherCases = getCombn(arr.slice(1));
        for (var i = 0; i < otherCases.length; i++) {
            for (var j = 0; j < arr[0].length; j++) {
                ans.push(arr[0][j] + ' - ' + otherCases[i])
            }
        }
        return ans
    }
}

var idArr = []

const ModalAdd = props => {
    const { control, register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            StatusId: 1,
            CategoryId: 0
        }
    })

    const [isBusy, setIsBusy] = useState(false)

    const [productStatus, setProductStatus] = useState([])

    const [childrenProduct, setChildrenProduct] = useState([])

    const [qty, setQty] = useState(0)

    useEffect(() => {
        const sttList = props.productStatus.map(item => {
            return {
                value: item.ProductStatusId,
                label: item.ProductStatusName
            }
        })
        setProductStatus(sttList)
    }, [props.productStatus])

    const handleGroupPriceChange = (value, groupId) => {
        const lst = getValues('ProductPrices')
        const index = lst.findIndex(n => n.GroupId === groupId)
        lst[index].UnitPrice = parseInt(value)
        setValue('ProductPrices', lst)
    }

    const renderGroupPrice = () => {
        const groupList = props.groupList
        return groupList.map((item, index) => {
            return <tr key={item.GroupId}>
                <td className="text-center">{index + 1}</td>
                <td>{item.GroupName}</td>
                <td>
                    <div className="input-group">
                        <NumberFormat
                            placeholder='Giá bán'
                            className="form-control"
                            // thousandSeparator={"."}
                            // decimalSeparator={","}
                            allowNegative={false}
                            defaultValue={0}
                            decimalScale={0}
                            onValueChange={val => {
                                const value = val.value
                                handleGroupPriceChange(value === '' ? 0 : value, item.GroupId)
                            }}
                        />
                        <span className="input-group-text">VNĐ</span>
                    </div>
                </td>
            </tr>
        })
    }

    const renderChildrenProduct = () => {
        if (childrenProduct === null) return <LoadingRow colSpan={4} />
        else if (childrenProduct.length === 0) return <EmptyRow colSpan={4} />
        else return childrenProduct.map((item, index) => <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td>{item.AttrName}</td>
            <td>
                <input type="text" className="form-control"
                    value={item.ExtensionName}
                    onChange={e => onChangeChildExtName(index, e.target.value)}
                />
            </td>
            <td>
                <NumberFormat
                    placeholder='Số lượng'
                    className="form-control"
                    // thousandSeparator={"."}
                    // decimalSeparator={","}
                    allowNegative={false}
                    defaultValue={0}
                    decimalScale={0}
                    value={item.Quantity}
                    onValueChange={val => {
                        const value = val.value
                        onChangeChildQty(index, value)
                    }}
                />
            </td>
        </tr>)
    }

    const onChangeChildExtName = (index, name) => {
        const lst = [...childrenProduct]
        lst[index].ExtensionName = name
        setChildrenProduct(lst)
    }

    const onChangeChildQty = (index, qty) => {
        const lst = [...childrenProduct]
        lst[index].Quantity = qty
        setChildrenProduct(lst)
    }

    const initCombn = (val, index) => {
        const lst = val.map(n => n.value + '')
        idArr[index] = lst

        const idList = []
        const combnArr = getCombn(idArr)
        combnArr.forEach(str => {
            const arr = (str + '').split('-')
            arr.forEach(num => {
                const id = parseInt(num)
                idList.push(id)
            })
        })

        const reqStr = idList.join('_')
        const url = `/admin/children-attribute/get-by-ids?ids=${reqStr}`
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                let temp = []
                combnArr.forEach(x => {
                    let attr_name = ''
                    let arr = (x + '').split('-')
                    arr.forEach(num => {
                        let id = parseInt(num)
                        let attr = data.Result.filter(n => n.ChildrenAttributeId === id)[0]
                        if (attr !== null) {
                            let name = attr.ChildrenAttributeName
                            attr_name += name + " - "
                        }
                    })
                    attr_name = attr_name.trim()
                    attr_name = attr_name.slice(0, -1)
                    attr_name = attr_name.trim()
                    temp.push({
                        AttrName: attr_name,
                        ExtensionName: "- " + attr_name,
                        Quantity: 0,
                        ChildrenAttrList: arr.map(n => parseInt(n))
                    })
                })
                setChildrenProduct(temp)
            }
        })
    }

    const renderAttribute = () => {
        let allAttr = props.allAttribute
        return allAttr.map((item, index) => {
            const childAttr = item.Children.map(child => {
                return {
                    value: child.ChildrenAttributeId,
                    label: child.ChildrenAttributeName
                }
            })
            return <div className="col-md-6 col-lg-6" key={item.AttributeId}>
                <div className="relative-position mb-2">
                    <label className="form-label">{item.AttributeName}</label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="custom-select"
                        isSearchable={false}
                        isClearable={false}
                        options={childAttr}
                        // value={value}
                        // onChange={onChange}
                        onChange={val => {
                            initCombn(val, index)
                        }}
                        placeholder='Giá trị thuộc tính'
                        isMulti={true}
                    />
                </div>
            </div>
        })
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                reset()
                idArr = []
                setChildrenProduct([])
                setQty(0)
            }}
            onShow={() => {
                let lst = props.groupList.map(item => {
                    return {
                        GroupId: item.GroupId,
                        UnitPrice: 0
                    }
                })
                setValue('ProductPrices', lst)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới sản phẩm</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                let proChildAttr = []
                idArr.forEach(n => {
                    const temp = n.map(x => parseInt(x))
                    proChildAttr = proChildAttr.concat(temp)
                })
                formData.ProductChildrentAttribute = proChildAttr

                const subProArr = []
                childrenProduct.forEach(n => {
                    subProArr.push({
                        ExtensionName: n.ExtensionName,
                        Quantity: n.Quantity,
                        ChildrentAttributes: n.ChildrenAttrList
                    })
                })
                formData.SubProducts = subProArr

                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <ul className="nav nav-tabs nav-bordered mb-3">
                        <li className="nav-item">
                            <a href="#tab-add-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                <span className="d-none d-md-block">Thông tin</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#tab-add-2" data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                <i className="mdi mdi-account-circle d-md-none d-block"></i>
                                <span className="d-none d-md-block">Giá theo nhóm</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#tab-add-3" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                                <span className="d-none d-md-block">Sản phẩm con</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content">
                        <div className="tab-pane show active" id="tab-add-1">
                            <div className="row">
                                <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                    <Controller
                                        control={control}
                                        name="AvatarUrl"
                                        render={({
                                            field: { onChange, value }
                                        }) => (
                                            <UploadImg
                                                imgUrl={value}
                                                onCompleted={data => {
                                                    onChange(data.Result.ImageUrl)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.AvatarUrl && <div className="validation-message">{errors.AvatarUrl.message}</div>}
                                </div>
                                <div className="col-sm-8 col-md-9 col-lg-9 col-xl-10">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Tên sản phẩm <span className="text-danger">*</span></label>
                                                <input {...register('ProductName')} type="text" className="form-control" placeholder="Tên sản phẩm" />
                                                {errors.ProductName && <div className="validation-message">{errors.ProductName.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-3 col-xxl-3">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Mã sản phẩm <span className="text-danger">*</span></label>
                                                <input {...register('ProductCode')} type="text" className="form-control" placeholder="Mã sản phẩm" />
                                                {errors.ProductCode && <div className="validation-message">{errors.ProductCode.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Mã sản phẩm nhà cung cấp</label>
                                                <input {...register('SupplierProductCode')} type="text" className="form-control" placeholder="Mã sản phẩm nhà cung cấp" />
                                                {errors.SupplierProductCode && <div className="validation-message">{errors.SupplierProductCode.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Nhóm sản phẩm <span className="text-danger">*</span></label>
                                                <Controller
                                                    control={control}
                                                    name="CategoryId"
                                                    render={({
                                                        field: { onChange, value }
                                                    }) => (
                                                        <CategoryDropdownTree
                                                            options={props.category}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                                {errors.CategoryId && <div className="validation-message">{errors.CategoryId.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Giá nhập</label>
                                                <div className="input-group">
                                                    <Controller
                                                        name="InputPrice"
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => {
                                                            return (
                                                                <NumberFormat
                                                                    placeholder='Giá nhập'
                                                                    className="form-control"
                                                                    // thousandSeparator={"."}
                                                                    // decimalSeparator={","}
                                                                    allowNegative={false}
                                                                    defaultValue={0}
                                                                    decimalScale={0}
                                                                    value={value}
                                                                    onValueChange={val => onChange(val.value)}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <span className="input-group-text">VNĐ</span>
                                                </div>
                                                {errors.InputPrice && <div className="validation-message">{errors.InputPrice.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Giá bán <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <Controller
                                                        name="OutputPrice"
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => {
                                                            return (
                                                                <NumberFormat
                                                                    placeholder='Giá bán'
                                                                    className="form-control"
                                                                    // thousandSeparator={"."}
                                                                    // decimalSeparator=","
                                                                    allowNegative={false}
                                                                    defaultValue={0}
                                                                    decimalScale={0}
                                                                    value={value}
                                                                    onValueChange={val => onChange(val.value)}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <span className="input-group-text">VNĐ</span>
                                                </div>
                                                {errors.OutputPrice && <div className="validation-message">{errors.OutputPrice.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                                            <div className="relative-position mb-2">
                                                <label className="form-label">Trạng thái</label>
                                                <Controller
                                                    name="StatusId"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <InputSelect
                                                                options={productStatus}
                                                                value={value}
                                                                defaultValue={1}
                                                                name='product-add'
                                                                variant='success'
                                                                onChange={onChange}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative-position">
                                <label className="form-label">Chi tiết sản phẩm</label>
                                <Controller
                                    control={control}
                                    name="Detail"
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <ReactQuill
                                            value={value}
                                            modules={Config.editorCfg}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                {errors.Detail && <div className="validation-message">{errors.Detail.message}</div>}
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-add-2">
                            <div className="table-responsive">
                                <table className="table table-centered table-input mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-52-px">#</th>
                                            <th>Tên nhóm</th>
                                            <th className="w-280-px">Giá bán</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderGroupPrice()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-add-3">
                            <div className="row">
                                {renderAttribute()}
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <div className="input-group">
                                        <span className="input-group-text">Số lượng</span>
                                        <NumberFormat
                                            placeholder='Số lượng'
                                            className="form-control"
                                            allowNegative={false}
                                            defaultValue={0}
                                            decimalScale={0}
                                            value={qty}
                                            onValueChange={val => {
                                                setQty(val.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-9'>
                                    <IconButton variant='success' icon={<IoShareSocialOutline />} onClick={() => {
                                        const list = [...childrenProduct].map(n => {
                                            return {
                                                ...n,
                                                Quantity: qty
                                            }
                                        })
                                        setChildrenProduct(list)
                                    }}>Đặt số lượng hàng loạt</IconButton>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-input mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-52-px">#</th>
                                            <th>Thuộc tính</th>
                                            <th>Tên mở rộng</th>
                                            <th className="w-280-px">Số lượng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderChildrenProduct()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal >
    )
}

ModalAdd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    category: PropTypes.array,
    productStatus: PropTypes.array,
    groupList: PropTypes.array,
    allAttribute: PropTypes.array
}

ModalAdd.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    category: [],
    productStatus: [],
    groupList: [],
    allAttribute: []
}

export default ModalAdd