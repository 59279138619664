import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoCopyOutline, IoGridOutline, IoReceiptOutline, IoServerOutline, IoTrashOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, AvatarRow, EmptyRow, FormatNumber, LoadingRow, ProductStatus } from '../../../../../components'

const Table = props => {
    const { onParentClick, onAvatarClick } = props

    const renderTable = () => {
        const data = props.data
        if (data === null) return <LoadingRow colSpan={9} />
        else if (data.length === 0) return <EmptyRow colSpan={9} />
        else return data.map((item, index) => <tr key={item.ProductId}>
            <td className="text-center">{(props.pageItem * (props.pageIndex - 1)) + index + 1}</td>
            <td>{item.ProductId}</td>
            <td>
                {item.IsParent ? <IoGridOutline className="me-1 text-primary" /> : <IoCopyOutline className="me-1 text-success" />}
                {item.ProductCode}
            </td>
            <td>
                <AvatarRow
                    url={item.AvatarUrl}
                    onClick={(urlObj) => {
                        onAvatarClick(urlObj)
                    }}
                    className="cursor-zoom"
                />
                <span className={item.IsParent ? 'cursor-point text-primary' : ''}
                    onClick={() => {
                        if (item.IsParent) {
                            onParentClick((item))
                        }
                    }}
                >
                    {item.ProductName}
                </span>
            </td>
            <td className="text-end text-primary">
                <FormatNumber>{item.OutputPrice}</FormatNumber>
            </td>
            <td className="text-end text-primary">
                <FormatNumber>{item.Quantity}</FormatNumber>
            </td>
            <td>{item.CategoryName}</td>
            <td>
                <ProductStatus className='cursor-point'
                    statusId={item.ProductStatusId}
                    onClick={() => props.onEditStatus(item)}
                />
            </td>
            <td>
                <ActionRow>
                    <ActionItem right="product_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="product_edit_partner_group" onClick={() => props.onEditPrice(item)} icon={<IoReceiptOutline />} title='Chỉnh sửa theo nhóm CTV' />
                    <ActionItem right="product_receipt" onClick={() => props.onEditQty(item)} icon={<IoServerOutline />} title='Cập nhật số lượng' />
                    <ActionItem right="product_delete" onClick={() => props.onDelete(item)} icon={<IoTrashOutline />} title='Xóa' className="text-danger" />
                </ActionRow>
            </td>
        </tr >)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered table-avatar mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>ID</th>
                        <th>Mã</th>
                        <th>Tên sản phẩm</th>
                        <th className='text-end'>Giá bán</th>
                        <th className='text-end'>SL</th>
                        <th>Nhóm</th>
                        <th>Trạng thái</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    onEdit: PropTypes.func,
    onEditPrice: PropTypes.func,
    onEditQty: PropTypes.func,
    onDelete: PropTypes.func,
    data: PropTypes.array,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
    onParentClick: PropTypes.func,
    onAvatarClick: PropTypes.func,
    onEditStatus: PropTypes.func
}

Table.defaultProps = {
    onEdit: () => { },
    onEditPrice: () => { },
    onEditQty: () => { },
    onDelete: () => { },
    data: null,
    pageItem: 10,
    pageIndex: 1,
    onParentClick: () => { },
    onAvatarClick: () => { },
    onEditStatus: () => { }
}

export default Table